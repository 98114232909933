/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
//
// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
//
Vue.component('Cart', require('./components/CartComponent.vue').default);
Vue.component('Button', require('./components/ButtonComponent.vue').default);
Vue.component('ProductList', require('./components/ProductListComponent.vue').default);
Vue.component('ProductDetails', require('./components/ProductDetails.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import {store} from "./store";
import {i18n, loadLanguageAsync} from "./i18n";

window.app = new Vue({
    store,
    i18n,
    created() {
        loadLanguageAsync(document.documentElement.lang)
    },
    el: '#app',
    data: {
        base_url: API_BASE_URL,
        search_key: '',
        search_results: [],
        loading: {
            cart: []
        },
        couponCode: ''
    },
    methods: {
        getScheduleFormatted:function(e){
            Number.prototype.padLeft = function(base,chr){
                var  len = (String(base || 10).length - String(this).length)+1;
                return len > 0? new Array(len).join(chr || '0')+this : this;
            }

            var d = new Date(e);
            var dformat = [(d.getMonth()+1).padLeft(),
                d.getDate().padLeft(),
                d.getFullYear()].join('/') +' ' +
               [d.getHours().padLeft(),
                d.getMinutes().padLeft(),
                d.getSeconds().padLeft()].join(':');

            return dformat
        },
        getSearchResult() {

        },
        updateCart(index) {
            var item = this.$store.state.cart[index]
            this.loading['cart'][index] = true
            axios.put(API_BASE_URL + '/cart/' + item.product.id, {
                options: {
                    quantity: item.quantity
                }
            }).then(res => {
                // console.log(res.data)
                this.$store.commit('UPDATE_CART', {
                    index: index,
                    service: res.data.body
                })
                this.loading['cart'][index] = false
            })
        },
        removeFromCart(index) {
            var item = this.$store.state.cart[index]
            axios.delete(API_BASE_URL + '/cart/' + item.product.id, {
                params: {
                    product_type: item.product_type
                }
            }).then(res => {
                this.$store.commit('REMOVE_FROM_CART', index)
            })
        },
        addCoupon() {
            axios.post(API_BASE_URL + '/cart/coupon', {
                code: this.couponCode
            }).then(response => {
                var res = response.data
                this.$store.commit('SET_COUPON', res)
            }).catch(err => {
                if (err.response) {
                    if (err.response.data.body && err.response.data.body.message) {
                        swal("Opps", err.response.data.body.message, "warning")
                    }
                }
            })
        },
        removeCoupon() {
            axios.delete(API_BASE_URL + '/cart/remove').then(response => {
                this.$store.commit('DELETE_COUPON', response)
            }).catch(err => {
                if (err.response) {
                    if (err.response.data.body && err.response.data.body.message) {
                        swal("Opps", err.response.data.body.message, "warning")
                    }
                }
            })
        }
    },
    watch: {
        search_key: function (newKey, oldKey) {
            if (newKey && newKey != oldKey) {
                this.$store.commit('SET_FILTER', {
                    key: 'keyword',
                    value: newKey
                });
                this.$store.commit('SET_FILTER', {
                    key: 'limit',
                    value: 10
                });
                axios.get(API_BASE_URL + '/service', {
                    params: this.$store.state.filters
                }).then(res => {
                    this.search_results = res.data.body
                })
            }
        }
    }
});
