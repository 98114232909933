<template>
    <div class="detail-product-middle">

        <h3>Tk {{ product.price }}</h3>
        <p v-if="product.del_price">
            <del style="color: #8c8c8c;font-size: 11px;">{{ product.del_price }}</del>
            <span id="discount-percentage">{{ product.discounted_percent }}%</span>
        </p>

        <div v-if="! existsInCart" id="qty">
            <span id="qtyText">Quantity : </span>
            <span id="qtyMinus" @click.prevent="quantity == 1?null:quantity--"> - </span>
            <input type="text" min="1" max="10" value="1" size="2" name="quantity" class="" id="qtyAmount"
                   v-model="quantity">
            <span id="qtyPlus" @click.prevent="quantity++"> + </span>
        </div>
        <a :href="blank_url" class="add-cart add-detail-anim-cart" @click.prevent="bookNowModalOpen(product.id)" style=" padding: 10px 20px; margin-top: 20px; display: inline-block; ">Book Now</a>
        <!-- <Button v-if="existsInCart" :loading="loading" @click.prevent="RemoveFromCart"
                class="add-cart add-detail-anim-cart">Remove From Cart
        </Button>
        <Button v-else :loading="loading" @click.prevent="prepareForCart(product)"
                class="add-cart add-detail-anim-cart">Book Now
        </Button> -->
        <PrepareForCart v-if="control" :control="control" @cancel="cancelCart" @cart="AddToCart"></PrepareForCart>
        <!-- Modal -->
        <div class="modal fade" v-bind:id="'bookNowModal'+product.id" tabindex="-1" role="dialog" aria-labelledby="bookNowModalLabel" style="margin-top:50px!important;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" id="myModalLabel">Book Now ( {{ product.title }} ) </h4>
                </div>
                <div class="modal-body">
                    <div id="user_auth" style="background-color: rgb(255, 255, 255); border-radius: 8px; position: relative; text-align: center; margin-top: 0px; min-height: 450px; box-shadow: rgba(170, 170, 170, 0.45) 0px 2px 10px; padding-top: 50px;">
                        <div class="alert alert-success successMessage" v-bind:id="'successMessage'+product.id" style="display:none;">
                            Service <span style="font-weight: 700!important;;">{{ product.title }}</span> booked successfully!
                        </div>
                        <form action="#" method="post" @submit.prevent="bookNow(product.id)">
                            <div>
                                <input type="text"  v-bind:id="'name'+product.id" placeholder="Full Name" name="full_name" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="text" v-bind:id="'phone'+product.id" placeholder="Phone" name="phone" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="text" v-bind:id="'address'+product.id" placeholder="Address" name="address" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="number" v-bind:id="'quantity'+product.id" placeholder="Quantity" name="quantity" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="date" v-bind:id="'request_date'+product.id" placeholder="Service Date" name="request_date" value="" required="required" class="auth_input">
                            </div>
                            <button type="submit" class="auth_button">Book Now</button>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import ButtonComponent from "./ButtonComponent"
    import PrepareForCart from "./PrepareForCart"

    export default {
        name: "ProductDetails",
        props: ['product'],
        data() {
            return {
                quantity: 1,
                date: '',
                loading: false,
                showModal: false,
                tempProduct: [],
                options: [],
                sendOptions: [],
                control: false,
                tempIndex: 0,
                base_url: API_BASE_URL,
                blank_url: '#',
                p_id: this.product.id,
                service_id: '',
                name: '',
                phone: '',
                address: '',
                quantity: '',
                request_date: '',
                pp: '',
            }
        },
        computed: {
            existsInCart() {
                var filt = this.$store.state.cart.filter(item => {
                    return item.product.id == this.product.id
                })
                return filt.length ? filt[0] : false
            }
        },
        methods: {
            cancelCart() {
                this.loading = false
                this.$store.commit('DELETE_PRODUCT_BEFORE_CART', {})
                this.control = false
            },
            prepareForCart(product) {
                this.loading = true
                this.$store.commit('SET_PRODUCT_BEFORE_CART', product)
                this.control = true
            },
            AddToCart(cartData) {
                this.control = false
                var opts = {
                    service_id: this.product.id,
                    quantity: this.quantity
                }
                if (cartData.priceToAdd) {
                    opts.price_to_add = cartData.priceToAdd
                }
                if (cartData.priceToDeduct) {
                    opts.price_to_deduct = cartData.priceToDeduct
                }
                if (cartData.metas) {
                    opts.options = cartData.metas
                }
                axios.post(API_BASE_URL + '/cart', opts).then(res => {
                    this.$store.commit('ADD_TO_CART', res.data.body)
                }).catch(e => {
                    console.log(e)
                }).finally(() => {
                    this.loading = false
                    this.$store.commit('DELETE_PRODUCT_BEFORE_CART', {})
                })
            },
            RemoveFromCart() {
                this.loading = true
                var index = this.$store.state.cart.findIndex(item => {
                    return item.product.id == this.product.id
                })
                var item = this.$store.state.cart[index]
                axios.delete(API_BASE_URL + '/cart/' + this.product.id, {
                    params: {
                        product_type: item.product_type
                    }
                }).then(res => {
                    this.$store.commit('REMOVE_FROM_CART', index)
                }).finally(() => {
                    this.loading = false
                })
            },
            bookNowModalOpen(product_id) {
                $(".successMessage").css("display", "none");
                this.service_id = product_id;
                $('#bookNowModal'+product_id).modal('show');
            },
            bookNow(product_id){
                this.service_id = product_id;
                this.name       = $('#name'+product_id).val();
                this.phone      = $('#phone'+product_id).val();
                this.address    = $('#address'+product_id).val();
                this.quantity    = $('#quantity'+product_id).val();
                this.request_date = $('#request_date'+product_id).val();
                axios.get(API_BASE_URL + '/submit/book_now', {
                    params:  {
                        service_id: this.service_id,
                        name: this.name,
                        phone: this.phone,
                        address: this.address,
                        quantity: this.quantity,
                        request_date: this.request_date,
                    }
                }).then(res => {
                    $("#successMessage"+product_id).css("display", "block");
                })
            }
        },
        components: {
            Button: ButtonComponent,
            PrepareForCart: PrepareForCart
        }
    }
</script>

<style scoped>

</style>
