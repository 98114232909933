import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        productBeforeCart: {},
        cart: [],
        filters: {},
        coupon: {}
    },
    mutations: {
        SET_FILTER(state, payload) {
            state.filters[payload.key] = payload.value
        },
        SET_PRODUCT_BEFORE_CART(state, payload) {
            state.productBeforeCart = payload
        },
        DELETE_PRODUCT_BEFORE_CART(state, payload) {
            state.productBeforeCart = {}
        },
        SET_COUPON(state, payload) {
            state.coupon = payload
        },
        DELETE_COUPON(state, payload) {
            state.coupon = {}
        },
        SET_CART(state, payload) {
            state.cart = payload
        },
        ADD_TO_CART(state, payload) {
            state.cart.push(payload)
        },
        UPDATE_CART(state, payload) {
            state.cart[payload.index] = payload.service
        },
        REMOVE_FROM_CART(state, payload) {
            state.cart.splice(payload, 1)
        }
    },
    getters: {
        totalCartPrice(state) {
            var sum = 0
            state.cart.map(item => {
                sum += (parseFloat(item.price) * parseInt(item.quantity))
            })
            return sum.toFixed(2)
        },
        totalCartPriceAfterCoupon(state) {
            var sum = 0
            state.cart.map(item => {
                sum += (parseFloat(item.price) * parseInt(item.quantity))
            })
            var price = sum-parseFloat(state.coupon.discount_taka)
            return price.toFixed(2)
        }
    }
})
