<template>
    <div class="product-list-item">
        <a :href="base_url + '/service/' + product.slug">
            <img :src="product.images[0]['thumb']" class="img-responsive">
        </a>
        <h3>
             <a :href="base_url + '/service/' + product.slug">
                {{ product.title }}
            </a>
            </h3>
        <p>
            <span>Tk {{ product.price }}</span>
            <del v-if="product.del_price">{{ product.del_price }}</del>
            <span v-if="product.del_price" style="float: right;">{{ product.discounted_percent }}%</span>
        </p>

        <!-- <Button v-if="existsInCart" :loading="loading" @click.prevent="$emit('remove')"
                class="add-to-cart-btn add-anim-cart">Remove From Cart
        </Button> -->
        <!-- <Button v-else :loading="loading" @click.prevent="$emit('prepare')" class="add-to-cart-btn add-anim-cart">Add TO Cart
        </Button> -->
        <a :href="blank_url" class="view-detail-btn" @click.prevent="bookNowModalOpen(product.id)">Book Now</a>
        <!-- Button trigger modal -->

        <!-- Modal -->
        <div class="modal fade" v-bind:id="'bookNowModal'+product.id" tabindex="-1" role="dialog" aria-labelledby="bookNowModalLabel" style="margin-top:50px!important;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <h4 class="modal-title" id="myModalLabel">Book Now ( {{ product.title }} ) </h4>
                </div>
                <div class="modal-body">
                    <div id="user_auth" style="background-color: rgb(255, 255, 255); border-radius: 8px; position: relative; text-align: center; margin-top: 0px; min-height: 450px; box-shadow: rgba(170, 170, 170, 0.45) 0px 2px 10px; padding-top: 50px;">
                        <div class="alert alert-success successMessage" v-bind:id="'successMessage'+product.id" style="display:none;">
                            Service <span style="font-weight: 700!important;;">{{ product.title }}</span> booked successfully!
                        </div>
                        <form action="#" method="post" @submit.prevent="bookNow(product.id)">
                            <div>
                                <input type="text"  v-bind:id="'name'+product.id" placeholder="Full Name" name="full_name" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="text" v-bind:id="'phone'+product.id" placeholder="Phone" name="phone" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="text" v-bind:id="'address'+product.id" placeholder="Address" name="address" value="" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="number" v-bind:id="'quantity'+product.id" placeholder="Quantity" name="quantity" required="required" class="auth_input">
                            </div>
                            <div>
                                <input type="date" v-bind:id="'request_date'+product.id" placeholder="Service Date" name="request_date" required="required" class="auth_input">
                            </div>
                            <button type="submit" class="auth_button">Book Now</button>
                        </form>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import ButtonComponent from "./ButtonComponent";ButtonComponent
    export default {
        name: "ProductItem",
        props: ['product', 'loading'],
        data() {
            return {
                base_url: API_BASE_URL,
                blank_url: '#',
                p_id: this.product.id,
                service_id: '',
                name: '',
                phone: '',
                address: '',
                quantity: '',
                request_date: '',
                pp: '',
            }
        },
        computed: {
            existsInCart() {
                var filt = this.$store.state.cart.filter(item => {
                    return item.product.id == this.product.id
                })
                return filt.length ? filt[0] : false
            }
        },
        components: {
            Button: ButtonComponent
        },
        methods:{
            bookNowModalOpen(product_id) {
                $(".successMessage").css("display", "none");
                this.service_id = product_id;
                $('#bookNowModal'+product_id).modal('show');
            },
            bookNow(product_id){
                this.service_id = product_id;
                this.name       = $('#name'+product_id).val();
                this.phone      = $('#phone'+product_id).val();
                this.address    = $('#address'+product_id).val();
                this.quantity    = $('#quantity'+product_id).val();
                this.request_date = $('#request_date'+product_id).val();
                console.log(this.quantity);
                console.log(this.request_date);
                axios.get(API_BASE_URL + '/submit/book_now', {
                    params:  {
                        service_id: this.service_id,
                        name: this.name,
                        phone: this.phone,
                        address: this.address,
                        quantity: this.quantity,
                        request_date: this.request_date,
                    }
                }).then(res => {
                    $("#successMessage"+product_id).css("display", "block");
                })
            }
        }
    }
</script>

<style scoped>

</style>
