<template>
    <div class="product-list" :style="{display: products.length?'grid':'none'}">
        <ProductItem v-if="products.length" v-for="(product, index) in products" :key="index" :product="product"
                     :loading="loading[index]" @prepare="prepareForCart(product, index)"
                     @remove="RemoveFromCart(product, index)"></ProductItem>
        <PrepareForCart v-if="control" :control="control" @cancel="cancelCart" @cart="AddToCart"></PrepareForCart>
    </div>
</template>

<script>
    import ProductItemComponent from "./ProductItemComponent";
    import PrepareForCart from "./PrepareForCart"

    export default {
        name: "ProductList",
        props: ['filter'],
        beforeMount() {
            axios.get(API_BASE_URL + '/service', {
                params: this.filter
            }).then(res => {
                this.products = res.data.body
            })
        },
        mounted() {
            this.$store.commit('SET_FILTER', this.filter);
            // console.log(this.$store.state.filters)
        },
        data() {
            return {
                date: '',
                loading: [],
                products: [],
                showModal: false,
                tempProduct: [],
                tempIndex: 0,
                control: false,
            }
        },
        methods: {
            cancelCart() {
                this.loading[this.tempIndex] = false
                this.$store.commit('DELETE_PRODUCT_BEFORE_CART', {})
                this.control = false
            },
            prepareForCart(product, index) {
                this.tempIndex = index
                this.loading[index] = true
                this.tempProduct = product
                this.$store.commit('SET_PRODUCT_BEFORE_CART', product)
                this.control = true
            },
            AddToCart(cartData) {
                this.control = false
                var opts = {
                    service_id: this.tempProduct.id,
                    quantity: 1
                }
                if (cartData.priceToAdd) {
                    opts.price_to_add = cartData.priceToAdd
                }
                if (cartData.priceToDeduct) {
                    opts.price_to_deduct = cartData.priceToDeduct
                }
                if (cartData.metas) {
                    opts.options = cartData.metas
                }
                axios.post(API_BASE_URL + '/cart', opts).then(res => {
                    this.$store.commit('ADD_TO_CART', res.data.body)
                }).catch(e => {
                    console.log(e)
                }).finally(() => {
                    this.loading[this.tempIndex] = false
                    this.$store.commit('DELETE_PRODUCT_BEFORE_CART', {})
                    this.$forceUpdate()
                })
            },
            RemoveFromCart(product, ind) {
                this.loading[ind] = true
                var index = this.$store.state.cart.findIndex(item => {
                    return item.product.id == product.id
                })
                var item = this.$store.state.cart[index]
                axios.delete(API_BASE_URL + '/cart/' + product.id, {
                    params: {
                        product_type: item.product_type
                    }
                }).then(res => {
                    this.$store.commit('REMOVE_FROM_CART', index)
                }).finally(() => {
                    this.loading[ind] = false
                })
            }
        },
        components: {
            ProductItem: ProductItemComponent,
            PrepareForCart: PrepareForCart
        }
    }
</script>

<style scoped>

</style>
