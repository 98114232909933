<template>
    <VueModal :control="control">
        <template slot="header">
            <slot name="header"></slot>
        </template>
        <template slot="body">
            <div v-for="(option, index) in options" :key="index"
                 :class="option.err?'form-group has-error':'form-group'">
                <label :for="'option-' + option.id">{{ option.title }} <span class="required"
                                                                             v-if="option.required">*</span></label>
                <input type="text" :id="'option-' + option.id" class="form-control" v-model="option.value"
                       v-if="option.input_type == 'text'" :required="option.required?true:false"
                       :placeholder="option.title">
                <textarea :id="'option-' + option.id" class="form-control" v-model="option.value"
                          v-if="option.input_type == 'textarea'" :required="option.required?true:false"
                          :placeholder="option.title"></textarea>
                <select :id="'option-' + option.id" class="form-control" v-model="option.value"
                        v-if="option.input_type == 'select' || option.input_type == 'multiple'"
                        :required="option.required?true:false" :multiple="option.input_type == 'multiple'"
                        :placeholder="option.title">
                    <option v-for="(row, index2) in option.rows" :key="index2" :value="index2">{{ row.title }} ({{ row.price_action == '1'?'Add':'Subtract' }} {{ row.price_amount }} BDT)</option>
                </select>
                <template v-if="option.input_type == 'checkbox'">
                    <div class="checkbox" v-for="(row, index2) in option.rows" :key="index2">
                        <label><input type="checkbox" :value="index2" v-model="option.value">{{ row.title }} ({{ row.price_action == '1'?'Add':'Subtract' }} {{ row.price_amount }} BDT)</label>
                    </div>
                </template>
                <template v-if="option.input_type == 'radio'">
                    <div class="radio" v-for="(row, index2) in option.rows" :key="index2">
                        <label><input type="radio" :value="index2" v-model="option.value">{{ row.title }} ({{ row.price_action == '1'?'Add':'Subtract' }} {{ row.price_amount }} BDT)</label>
                    </div>
                </template>
                <span v-if="option.err" :id="'help-option-' + option.id" class="help-block">You Must Fill This</span>
            </div>
        </template>
        <template slot="footer">
            <slot name="footer">
                <button class="btn btn-danger" @click.prevent="optionCancel">CANCEL</button>
                <button class="btn btn-primary" @click.prevent="optionSubmitted">OK</button>
            </slot>
        </template>
    </VueModal>
</template>

<script>
    import VueModal from './VueModal'

    export default {
        name: "ProductOptionSelector",
        props: ['options', 'control'],
        data() {
            return {
                validations: []
            }
        },
        beforeMount() {
            this.options.forEach((option, index) => {
                if (option.input_type == 'multiple') {
                    this.options[index].value = []
                    this.$forceUpdate()
                    if (option.rows.length) {
                        this.options[index].value.push(0)
                        this.$forceUpdate()
                    }
                }
                if (option.input_type == 'checkbox') {
                    this.options[index].value = []
                    this.$forceUpdate()
                }
                if (option.input_type == 'select') {
                    this.options[index].value = null
                    this.$forceUpdate()
                    if (option.rows.length) {
                        this.options[index].value = 0
                        this.$forceUpdate()
                    }
                }
                // if (option.id == 10) console.log(this.options[index].value)
                this.options[index].required = parseInt(option.is_required)
                this.$forceUpdate()
            })
        },
        methods: {
            optionCancel() {
                this.$emit('cancel')
            },
            optionSubmitted() {
                var flag = true
                var priceToAdd = 0
                var priceToDeduct = 0
                var metas = {}

                var ind = 0
                while (ind < this.options.length) {
                    var opt = this.options[ind]
                    if (opt.required) {
                        if ((opt.input_type == 'text' || opt.input_type == 'textarea' || opt.input_type == 'select' || opt.input_type == 'radio') && (typeof opt.value == 'undefined' || typeof opt.value == null || opt.value == '') && opt.value != 0) {
                            this.options[ind].err = true
                            this.$forceUpdate()
                        } else if ((opt.input_type == 'multiple' || opt.input_type == 'checkbox') && opt.value.length == 0) {
                            if (opt.id == 9) console.log(opt.value)
                            this.options[ind].err = true
                            this.$forceUpdate()
                        } else {
                            this.options[ind].err = false
                            this.$forceUpdate()
                        }
                    }
                    if (opt.err) {
                        flag = false
                    } else {
                        if (flag) {
                            if (opt.input_type == 'select' || opt.input_type == 'radio') {
                                var r = opt.rows[opt.value]
                                metas[opt.title] = r.title
                                if (parseInt(r.price_action) == 1) {
                                    priceToAdd += parseFloat(r.price_amount)
                                } else if (parseInt(r.price_action) == 2) {
                                    priceToDeduct += parseFloat(r.price_amount)
                                }
                            } else if (opt.input_type == 'multiple' || opt.input_type == 'checkbox') {
                                metas[opt.title] = []
                                opt.value.forEach(ri => {
                                    var r = opt.rows[ri]
                                    metas[opt.title].push(r.title)
                                    if (parseInt(r.price_action) == 1) {
                                        priceToAdd += parseFloat(r.price_amount)
                                    } else if (parseInt(r.price_action) == 2) {
                                        priceToDeduct += parseFloat(r.price_amount)
                                    }
                                })
                            } else {
                                metas[opt.title] = opt.value
                            }
                        }
                    }

                    ind++
                }

                if (flag) {
                    var results = {
                        priceToAdd: priceToAdd,
                        priceToDeduct: priceToDeduct,
                        metas: metas
                    }
                    this.$emit('option', results)
                }
            },
        },
        components: {
            VueModal: VueModal
        }
    }
</script>

<style scoped>
    span.required {
        color: #E50000;
    }
</style>
