<template>
    <button v-if="loading" disabled><i class="fa fa-spinner fa-pulse fa-fw"></i><span class="sr-only">Loading</span></button>
    <button v-else @click.prevent="$emit('click', $event)"><slot></slot></button>
</template>

<script>
    export default {
        name: "Button",
        props: {
            loading: {
                type: Boolean
            }
        }
    }
</script>

<style scoped>

</style>
