<template>
    <div class="prepare-cart">
        <ProductOptionSelector :control="control && showOption" :options="$store.state.productBeforeCart.options"
                               @option="optionSubmitted" @cancel="$emit('cancel')">
            <template slot="header">
                <h4>{{ $store.state.productBeforeCart.title }}</h4>
            </template>
        </ProductOptionSelector>
        <Modal
            v-if="showModal"
            :isOpen="showModal"
            :value="$store.state.productBeforeCart"
            type="datetime"
            v-model="date"
            value-zone="Asia/Dhaka"
            zone="Asia/Dhaka"
            :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
            :hour-step="1"
            :minute-step="1"
            :week-start="7"
            use12-hour
            auto
            @close="submitSchedule"
        ></Modal>
    </div>
</template>

<script>
    import ProductOptionSelector from "./ProductOptionSelector"
    import ScheduleModalComponent from "./ScheduleModalComponent"

    export default {
        name: "PrepareForCart",
        props: ['control'],
        data() {
            return {
                processingProduct: {},
                showOption: false,
                showModal: false,
                date: '',
                priceToAdd: 0,
                priceToDeduct: 0,
                metas: {}
            }
        },
        mounted() {
            if (this.$store.state.productBeforeCart.options.length) {
                this.showOption = true
            } else {
                this.showModal = true
            }
        },
        methods: {
            optionSubmitted(result) {
                this.priceToAdd = result.priceToAdd
                this.priceToDeduct = result.priceToDeduct
                this.metas = result.metas

                this.showOption = false
                this.showModal = true

                this.$emit('option', result)
            },
            submitSchedule() {
                if (this.date.length) {
                    var data = this.date
                } else {
                    var data = ''
                }
                this.metas['schedule'] = data
                var cartData = {
                    priceToAdd: this.priceToAdd,
                    priceToDeduct: this.priceToDeduct,
                    metas: this.metas
                }
                this.showModal = false
                this.$emit('cart', cartData)
            }
        },
        components: {
            Modal: ScheduleModalComponent,
            ProductOptionSelector: ProductOptionSelector
        }
    }
</script>

<style scoped>

</style>
