<template>
    <div id="mini-cart-container" :style="{display: items.length?'block':'none'}">

        <div v-for="(item, index) in items" :key="index" class="mini-cart">
            <div class="mini-cart-pro-img">
                <img :src="item.product.images[0]['thumb']" :alt="item.product.title">
            </div>
            <div class="mini-cart-pro-content">
                <h3>{{ item.product.title }}</h3>
                <h4>{{ item.product.excerpt }}</h4>
                <p class="price">Tk {{ item.price }} x {{ item.quantity }}</p>
            </div>
            <div class="mini-cart-pro-remove" @click.prevent="removeFromCart(index)">&#10006</div>
        </div>

        <div id="mini-cart-pro-info">
            <div class="mini-cart-info">
                <div class="total"><b>Total :</b>Tk {{ total }}</div>
            </div>
            <a class="mini-cart-cart" :href="base_url + '/cart-page'">Cart</a>
        </div>

    </div>
</template>

<script>
    export default {
        name: "cart",
        created() {
            axios.get(API_BASE_URL + '/cart').then(res => {
                if (res.data.cart) {
                    this.$store.commit('SET_CART', res.data.cart)
                    this.items = res.data.cart
                }
                if (res.data.coupon) {
                    this.$store.commit('SET_COUPON', res.data.coupon)
                }
            })
        },
        data() {
            return {
                items: [],
                base_url: API_BASE_URL
            }
        },
        methods: {
            removeFromCart(index) {
                var item = this.items[index]
                axios.delete(API_BASE_URL + '/cart/' + item.product.id, {
                    params: {
                        product_type: item.product_type
                    }
                }).then(res => {
                    this.$store.commit('REMOVE_FROM_CART', index)
                })
            }
        },
        computed: {
            total() {
                return this.$store.getters.totalCartPrice
            }
        }
    }
</script>

<style scoped>

</style>
